<template>
  <div class="schedule-row" :class="xScroll>0 ? 'scroll' : ''">
          <div class="schedule-row__workplace" @contextmenu.prevent="" ref="workplace">      
              <div class="schedule-row__title-block" :style="xScroll>0 ? {transform:`translate3d(${xScroll}px, 0, 0)`} : ''">                        
                <div class="schedule-row__title">
                  <tooltip bottom>
                    <v-avatar :color="comPlace.comRoom.comFloor.comBuilding.comBranch.color" size="10" style="margin-top:-1px"/>
                    {{comPlace.comRoom.sid}}

                    <v-progress-circular 
                    v-if="comPlace.comPlaceLoad" 
                      :rotate="-90"
                      :size="20"
                      :width="2"
                      :value="comPlace.comPlaceLoad.percentage"
                      color="primary"
                      class="schedule-row__title-indicator"
                    >
                      <span class="schedule-row__title-indicator-text">{{ comPlace.comPlaceLoad.percentage }}</span>
                    </v-progress-circular>
                    <template v-slot:content>
                      <div>
                      Филиал: <span class="font-weight-regular">{{ comPlace.comRoom.comFloor.comBuilding.comBranch.name }}</span><br>
                      Кабинет: <span class="font-weight-regular">{{comPlace.comRoom.sid}} {{comPlace.comRoom.name}}</span><br>
                      Рабочее место: <span class="font-weight-regular">{{comPlace.name}}</span>
                      </div>
                      <template v-if="comPlace.comPlaceLoad">
                      <v-divider class="my-3"/>
                      <div>
                        <p class="mb-1">Загрузка рабочего места:</p>
                        Доступно: <span class="font-weight-regular">{{comPlace.comPlaceLoad.totalHours }} ч.</span><br>
                        Занято: <span class="font-weight-regular">{{comPlace.comPlaceLoad.totalUserSpecPlanHours }} ч.</span><br>
                        Занято на: <span class="font-weight-regular">{{comPlace.comPlaceLoad.percentage}} %</span>
                      </div>
                      </template>
                    </template>
                  </tooltip>
                </div>                      
                <div class="schedule-row__subtitle">
                    {{comPlace.comRoom.name}} ({{comPlace.name}})</div>
              </div> 
          </div>
          
          <div class="workday" v-for="day in week" :key="day.date+'_'+comPlace.id" 
            :style="rowStyle(day)"

            @mousemove="$emit('mouseMove',$event, day, comPlace)"
            
            @contextmenu.prevent=""
            
            @mouseup.left.stop="loadState.isSuccess&&eventStretching.length>0 ? $emit('finishStretching',eventStretching[0]) : null"
            @click="eventStretching.length===0&&eventCopy===null ? userSpecPlanState.modalOpen({
              begin:$tools.dateTimeFormat(day.dateYMD+' '+selectedTime, 'YYYY-MM-DD HH:mm'),
              end:$tools.dateTimeFormat(day.dateYMD+' '+selectedTime, 'YYYY-MM-DD HH:mm', null, 60),
              type:'workVisit',
              comPlace:comPlace,
              gridMinutes:15,
              userSpec:{
                commentAdmin:null,
              },
              dayTimeBegin: day.time[0],
              dayTimeEnd: day.time[day.time.length-1]
            }) : eventCopy ? $emit('finishCopy') : null" 
          >
            
              <div v-if="eventDrag.length === 0&&selectedPlace!=null&&selectedPlace.id === comPlace.id&&selectedDate === day.dateYMD" :style="tooltipStyle" class="tooltip">
                <span>{{ $tools.date(selectedDate) }}<br> {{selectedTime}}</span>
              </div>  

             
              <UserSpecPlanCard 
                v-if="eventDrag.length>0||eventStretching.length>0"
                :events="eventDrag.length>0 ? eventDrag : eventStretching.length>0 ? eventStretching : []" 
                :eventDrag="eventDrag" 
                :eventStretching="eventStretching"
                :eventCopy="eventCopy"
                :comPlace="comPlace" 
                :day="day" 
                :keyPressed="keyPressed"
                :loadState="loadState"
                :userSpecPlanState="userSpecPlanState"
                :zoom="zoom"
                @cursorStyle="(v)=>$emit('update:cursorStyle',v)"
                @hideTooltip="$emit('hideTooltip')"
                @startCopy="($event,event)=>$emit('startCopy', $event, event)"
                @startDrag="(event)=>$emit('startDrag',event)"
                @startStretching="(event,day)=>$emit('startStretching',event,day)"
              />
  
              <UserSpecPlanCard 
                :events="events" 
                :eventDrag="eventDrag" 
                :eventStretching="eventStretching"
                :eventCopy="eventCopy"
                :comPlace="comPlace"  
                :day="day" 
                :keyPressed="keyPressed"
                :loadState="loadState"
                :userSpecPlanState="userSpecPlanState"
                :zoom="zoom"
                @cursorStyle="(v)=>$emit('update:cursorStyle',v)"
                @hideTooltip="$emit('hideTooltip')"
                @startCopy="($event,event)=>$emit('startCopy', $event, event)"
                @startDrag="(event)=>$emit('startDrag',event)"
                @startStretching="(event,date,dayTimeEnd)=>$emit('startStretching',event,date,dayTimeEnd)"
              />
              
          </div>
      </div> 
</template>


<script>
//import moment from "moment";
import UserSpecPlanCard from "@/views/UserSpec/components/UserSpecPlanCard";
import Tooltip from "@/componentsV2/base/Tooltip.vue";

export default {
  props: ['comPlace','events','cellWidth','zoom','sidebarCollapse','week','xScroll','eventCopy','eventDrag','eventStretching','selectedPlace','keyPressed','loadState','userSpecPlanState','selectedDate','selectedTime','tooltipStyle','cursorStyle'],
  components: {UserSpecPlanCard,Tooltip },
  // data: () => ({

  // }),
  mounted(){
    this.$emit('xScrollInitial',this.$refs.workplace.getBoundingClientRect().left);
  },
  methods: {
    rowStyle(day) {
      return { 'width': (day.time.length-1) * this.cellWidth + 'px',backgroundSize: this.cellWidth +'px 100%'};
    },
  }
}
</script>

<style lang="scss" scoped>
  $indent: 200px;
  $border: #e0e0e0 1px solid;
  $cell: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABGCAYAAADb7SQ4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMi40IChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0MUI2QTBBQzBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0MUI2QTBBRDBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQxQjZBMEFBMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQxQjZBMEFCMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+5I7JhQAAAEVJREFUeNrszUEBACAMBKCbRda/1ZpoC30IBaiZ2Umqu3PTyiNisVgsFovFYrFYLBaLxWKxWCwWi8VisVgsFov/iI8AAwAdNQSJa6ulbwAAAABJRU5ErkJggg==') top left/ 30px 100% repeat-x;
  %separator {
        content: "";
        position: absolute;      
        transform: translate3d(-1px, 0, 0);
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: 3px 0px 0px 0px #ffc10765;
        z-index: 9;
  }
  %cell-size {
    width: 30px;
  }

  .schedule-row {
    position: relative;
    border-right: 0;
    display: flex;
    &__workplace {
      display: flex;
      align-items: center;
      font-size: .9rem;
      font-weight: bold;   
      width: $indent;
      min-height: 71px;
      height: auto;
      padding: .5rem;
      border: $border;
      border-right: none;
      border-bottom: none;
    }  
    &__title-block {
        position: absolute;
        background: #ffffff;
        max-width: 190px;
        padding-right: 5px;
        border-radius: 10px;
        z-index: 9999999;
        transition: transform .3s;
        cursor:pointer;
    }
    &__title {
      font-size: 1rem;
      display: flex;
      align-items: center;
      line-height: 1;
      padding-block: 5px;
      &-indicator {
        font-size: 10px;
        font-weight: normal;
        margin-left: 5px;
        &-text {
          color:#000000;
        }
      }
    }
    &__subtitle {
      font-size: .75rem;
      line-height: 1rem;
      font-weight: normal;
    }

    // &.scroll #{&}__title {
    //   font-size: .7rem !important;
    //   line-height: 1.2rem;
    //   margin-top: 0 !important;
    //   left: 0;
    //   top: 2px;
    //   width: 100%;
    //   text-align: center;
    // }
    &.scroll #{&}__title-block {
      border: 1px solid #e0e0e0;
      padding-inline: .3125rem;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    }
    &.scroll #{&}__subtitle {
      display: none;
    }

    // &.scroll #{&}__title-block {
    //   // box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    //   padding: 10px;
    //   //transition: all .3s;
    //   width: 60px;
    //   height: 25px;
    //   overflow: hidden;
    //   border: 2px solid #1976d2;

    //   &:hover {
    //     width: 100%;
    //     height: auto;
    //     .schedule-row__title {
    //       text-align: left;
    //       left: 5px;
    //     }
    //     .schedule-row__subtitle {
    //       opacity: 1;
    //     }
    //   }
    // }  
      
  }

  .workday {
      position: relative;
      display: flex;
      background: $cell; 
      border-top: $border;
      height: auto;
      &::after {
        @extend %separator;
      }
  }  

  .tooltip {
    position:absolute;
    min-width:20px;
    height:100%;
    opacity: 0;
    pointer-events: none;
    display:flex;
    align-items:center;
    font-size: .825rem;
    padding:.825rem .825rem .825rem 1rem;
    transform: translate3d(0, 0, 0);
    z-index: 999;
    &:before {
      content:'';
      position:absolute;
      width:5px;
      height:100%;
      transform: translate3d(-16px, 0, 0);
      background:#1976d2;
    }
    span {
      background: #1976d2;
      color: #ffffff;
      padding: 0.3rem;
      border-radius: 3px;
    }
  }

</style>