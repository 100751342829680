<template>
  <v-textarea
      v-model="localValue"
      :error-messages="localValueError"
      clearable
      :auto-grow="typeof autoGrowDisabled === 'undefined' ? true : false"
      :rows="typeof rows === 'undefined' ? 1 : rows"
      outlined dense hide-details="auto"
      style="margin-bottom: 16px"
      ref="el"
      :disabled="typeof disabled==='undefined' ? false : disabled"
  >
    <template v-slot:label>
      <v-icon v-if="!$tools.isEmpty(beforeIcon)" size="18" style="margin-top:-2px" :color="beforeIconColor">{{ beforeIcon }}</v-icon>
      {{name}}
    </template>
    <template v-slot:append>
      <v-icon v-if="!$tools.isEmpty(afterIcon)" size="18" style="margin-top:3px" :color="afterIconColor">{{ afterIcon }}</v-icon>
    </template>
  </v-textarea>
</template>

<script>
export default {
  props: ['name', 'beforeIcon', 'afterIcon', 'beforeIconColor', 'afterIconColor', 'value','format','formatExample','required', 'disabled', 'autoGrowDisabled', 'rows'],
  data: () => ({
    localValue: null,
  }),
  mounted() {
    this.localValue = this.value;
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
    localValue() {
      this.$emit('update:value', this.localValue)
    }
  },
  computed:{
    localValueError:{
      get(){
        if(typeof this.required!=='undefined' && this.$tools.isEmpty(this.localValue))
          return ['Поле обязательное'];

        if(this.$tools.isEmpty(this.format) || this.$tools.isEmpty(this.localValue))
          return [];

        if(new RegExp(this.format).test(this.localValue))
          return [];

        return ['Неверный формат'+(this.$tools.isEmpty(this.formatExample) ? '' : '. Пример: '+this.formatExample)];
      },
      set(){
      }
    }
  },
  methods:{
    focus(){
      this.$refs.el.focus();
    }
  }
}
</script>