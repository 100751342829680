<template>
  <v-date-picker
      v-model="day"
      :show-current="true"
      :first-day-of-week="1"
      :events="usersSelectedEvents"
      :event-color="date => usersSelectedEventsColors[date]"
      no-title
      show-adjacent-months
      color="primary"
      full-width
  /> 
</template>
<script>


import moment from "moment";

export default {

  props:['daySelected','usersSelectedEventsColors', 'cookieName', 'events'],
  data:()=>({
    day:moment(new Date()).format('YYYY-MM-DD'),
    cookieValue: null,
    usersSelectedEvents: []
  }),
  watch:{
    day() {
      this.$emit('update:daySelected', this.day);
      this.$cookie.set(this.cookieName, this.day, 30);
    },
    events() {
      this.usersSelectedEvents = this.events;
    },
    daySelected(){
      this.day = this.daySelected;
    }
  },
  mounted(){
    this.cookieValue = this.$cookie.get(this.cookieName);

    if (moment(this.cookieValue).isValid()) {
      this.day = this.cookieValue;
    } else if (this.daySelected) {
      this.day = this.daySelected;
    }

    if (this.events) {
      this.usersSelectedEvents = this.events;
    }

    this.$emit('update:daySelected', this.day);
  }
}
</script>

<style lang="scss">
  .v-date-picker-table__events {
    &>div {
      border: 1px solid #ffffff !important;
    }
  }
</style>